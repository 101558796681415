import React, { useMemo } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';
import Loader from './Loader';
import {
  useUpdatePatientMutation,
  useReadEventMutation,
} from '../../patient-app-common/api/patientApi';
import useAppEvents from '../../patient-app-common/hooks/useAppEvents';
import { useAppSelector } from '../../redux/hooks';

// button with hover menu to be used with NavBar
export default function NavDemo({ patient }) {
  const { demo } = useAppSelector(({ auth }) => auth);

  const { completedEvents } = useAppEvents();

  const [updatePatient, { isLoading: isLoadingUpdatePatient }] = useUpdatePatientMutation();
  const [readEvent, { isLoading: isLoadingReadEvent }] = useReadEventMutation();
  const isLoading = isLoadingUpdatePatient || isLoadingReadEvent;

  const PROGRAM_TYPE = useMemo(() => {
    let program = 'surgical';
    
    if (patient?.maternityProgram) {
      program = 'maternity';
    }

    if (patient?.nonSurgicalProgram) {
      program = 'chronic';
    }

    return program;
  }, [patient])

  if (!demo || !patient) return null;

  const setSurgicalDate = async (days) => {
    const surgeryDate = moment()
      .startOf('day')
      .add(days, 'days')
      .unix();

    const params = {
      surgeryDate,
      dischargeDate: 0,
      completedDate: 0,
    };

    await updatePatient(params);
  };

  const setDischargeDate = async (days) => {
    const dischargeDate = moment()
      .startOf('day')
      .add(days, 'days')
      .unix();

    const surgeryDate = moment.unix(dischargeDate)
      .subtract(1, 'days')
      .unix();

    const params = {
      surgeryDate,
      dischargeDate,
      completedDate: 0,
    };

    await updatePatient(params);
  };

  const setSignupDate = async (days) => {
    const signupTime = moment()
      .startOf('day')
      .add(days, 'days')
      .unix();

    // TODO: set completed date to createdAt + program length
    const params = {
      createdAt: signupTime,
      completedDate: 0,
    };
    await updatePatient(params);
  };

  const setEndProgram = async () => {
    await updatePatient({ completedDate: moment().unix() });
  };

  const handleResetEvents = async () => {
    const eventsToUnread = [...completedEvents];

    await Promise.all(eventsToUnread.map(async (event) => {
      await readEvent({
        eventId: event.applicationEventId,
        value: false.toString(),
      }).unwrap();
    }));
  };

  return (
    <div className="relative group hidden lg:block" aria-hidden="true">
      <Loader visible={isLoading} />
      <Btn><span>Demo</span></Btn>
      <GroupHover>
        <Menu>
          {PROGRAM_TYPE === 'surgical' && (
            <>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSurgicalDate(7)}
              >
                <p>7 days before surgery</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSurgicalDate(1)}
              >
                <p>1 day before surgery</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSurgicalDate(-1)}
              >
                <p>1 day after surgery</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setDischargeDate(0)}
              >
                <p>Day of discharge</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
              >
                <Link aria-hidden="true" to="/tracker/daily">
                  <p>Do daily health check</p>
                </Link>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setEndProgram()}
              >
                <p>Show end of program</p>
              </MenuItem>
            </>
          )}

          {PROGRAM_TYPE === 'maternity' && (
            <>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSurgicalDate(27*7)}
              >
                <p>13 weeks pregnant (2nd trimester)</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSurgicalDate(13*7)}
              >
                <p>27 weeks pregnant (3rd trimester)</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSurgicalDate(7)}
              >
                <p>1 week before due date</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSurgicalDate(1)}
              >
                <p>1 day before due date</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSurgicalDate(0)}
              >
                <p>Due date</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setDischargeDate(0)}
              >
                <p>Day of discharge</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setDischargeDate(-7)}
              >
                <p>1 week after discharge</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
              >
                <Link aria-hidden="true" to="/tracker/daily">
                  <p>Do daily health check</p>
                </Link>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setEndProgram()}
              >
                <p>Show end of program</p>
              </MenuItem>
            </>
          )}

          {PROGRAM_TYPE === 'chronic' && (
            <>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSignupDate(0)}
              >
                <p>Day of sign up</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSignupDate(-7)}
              >
                <p>7 days after sign up</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSignupDate(-14)}
              >
                <p>14 days after sign up</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setSignupDate(-28)}
              >
                <p>28 days after sign up</p>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
              >
                <Link aria-hidden="true" to="/tracker/daily">
                  <p>Do daily health check</p>
                </Link>
              </MenuItem>
              <MenuItem
                aria-hidden="true"
                onClick={() => setEndProgram()}
              >
                <p>Show end of program</p>
              </MenuItem>
            </>
          )}

          <MenuItem
            aria-hidden="true"
            onClick={() => handleResetEvents()}
          >
            <p>Reset all messages</p>
          </MenuItem>

          <MenuItem
            aria-hidden="true"
          >
            <Link
              aria-hidden="true"
              to="/demo"
            >
              <p>More demo controls</p>
            </Link>
          </MenuItem>
        </Menu>
      </GroupHover>
    </div>
  );
}

const Btn = styled.button(() => [
  tw`bg-blue-10 flex flex-row items-center px-8`,
  css`height:3.75rem`,
]);

const GroupHover = styled.div(() => [
  tw`absolute z-50`,
  tw`hidden group-hover:block`,
  css`width:20rem`,
]);

const Menu = styled.nav(() => [
  tw`bg-white flex-col p-2 shadow`,
]);

const MenuItem = styled.div(() => [
  tw`cursor-pointer p-2`,
  tw`hover:bg-blue-10`,
]);
